/**
 * Used to show parallax effect in heros. Does NOT work if used for sections below the hero
 */

function parallaxScroll() {
    const scrollDistance = window.scrollY || window.pageYOffset;
    const container = document.querySelector('.js-parallax');
    const opacityGroup = document.querySelectorAll('.js-parallax__opacity');
    const translateGroup = document.querySelectorAll('.js-parallax__translate');

    // To Do: Add Modernizr
    // if (!Modernizr.touch) {
    // Alter opacity
    opacityGroup.forEach(function (element) {
        element.style.opacity = 1 - scrollDistance / 500;
    });

    // Alter scrolling speed
    translateGroup.forEach(function (element) {
        element.style.transform = `translate3d(0px, ${
            0.2 * scrollDistance
        }px, 0px)`;
    });
    // }
}

// You can call parallaxScroll() whenever necessary to apply the parallax effect.
module.exports = parallaxScroll;
