/**
 * Hamburger
 *
 * Runs the functionality of clicking the hamburger icon in the top nav.
 */

/**
 * Toggle the hamburger functionality.
 */
export default () => {
    const selector = '.js-hamburger';
    const activeClass = 'is-active';
    const hScrollLockNavActive = 'h-scroll-lock';
    const navActive = 'nav-active';
    const headerActiveClass = 'o-header--hamburger-active';
    const navMenu = '.js-slide-out';
    const navMenuActiveClass = 'm-slide-out--active';
    const headerSearchButton = 'o-header-1__button-search';
    const searchBar = 'm-search-bar';

    /**
     * Toggle the hamburger menu.
     */
    function toggleMenu() {
        const hamburger = document.querySelector(selector);
        const body = document.querySelector('.js-body');
        const header = document.querySelector('.js-header');
        const menu = document.querySelector(navMenu);
        const searchButton = document.querySelector(`.${headerSearchButton}`);
        const searchInput = document.querySelector(`.${searchBar}`);

        hamburger.classList.toggle(activeClass);
        body.classList.toggle(hScrollLockNavActive);
        body.classList.toggle(navActive);
        header.classList.toggle(headerActiveClass);
        menu.classList.toggle(navMenuActiveClass);
        searchButton.classList.toggle(`${headerSearchButton}--anim`);

        if (searchInput.classList.contains(`${searchBar}--active`)) {
            const searchButtonSibling =
                hamburger.parentElement.nextElementSibling;
            if (searchButtonSibling) {
                searchButtonSibling
                    .querySelector(`.${headerSearchButton}`)
                    .click();
            }
        }

        $('.js-header-top').slideToggle(250);
    }

    /**
     * Click event handler for the hamburger icon.
     * @param {Event} e - The click event.
     */
    const hamburgerElement = document.querySelector(selector);
    hamburgerElement.addEventListener('click', (e) => {
        e.preventDefault();
        toggleMenu();
    });

    /**
     * Mouseup event handler to close the hamburger menu when clicking outside.
     * @param {Event} e - The mouseup event.
     */
    document.addEventListener('mouseup', (e) => {
        const isHamburgerActive =
            hamburgerElement.classList.contains(activeClass);
        const isClickOutsideMenu =
            !hamburgerElement.isSameNode(e.target) &&
            !hamburgerElement.contains(e.target);
        const isNotNavMenu = !document
            .querySelector(navMenu)
            .contains(e.target);

        if (isHamburgerActive && isClickOutsideMenu && isNotNavMenu) {
            toggleMenu();
        }
    });

    /**
     * Resize event handler to close the hamburger menu when the window width exceeds a certain threshold.
     */
    window.addEventListener('resize', () => {
        const windowWidth = window.innerWidth;
        const isHamburgerActive =
            hamburgerElement.classList.contains(activeClass);

        if (windowWidth >= 1140 && isHamburgerActive) {
            toggleMenu();
        }
    });
};
