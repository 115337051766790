import AOS from 'aos';

function copyButton() {
    const selector = {
        button: '.js-copy-button',
        messageTemplate: '.message-template',
        label: '.a-btn__label',
        labelInner: '.a-btn__label-inner',

        accordionMobile: '.o-article-single-1__accordion',
        accordionWrapper: '.o-article-single-1__accordion-inner',
        accordionMobileTitle: '.o-article-single-1__accordion-mobile-title',
    };

    // Define a function to copy text to the clipboard
    const copyText = () => {
        // Select the message template element
        const messageTemplate = document.querySelector(
            selector.messageTemplate,
        );
        // Get the text content from the message template
        let textToCopy = messageTemplate.innerText;

        // Attempt to write the text to the clipboard
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                // If successful, update the label to indicate "Copied"
                const labelInner = document.querySelector(selector.labelInner);
                if (labelInner) {
                    const label = labelInner.closest(selector.label);
                    if (label) {
                        // Store the original text and inner text of the label
                        const originalText = label.dataset.text || '';
                        const originalLabelInnerText =
                            labelInner.textContent || '';

                        // Update the label and inner text to indicate "Copied"
                        label.dataset.text = 'Copied';
                        labelInner.textContent = 'Copied';

                        // Reset the label text after 2000 milliseconds (2 seconds)
                        setTimeout(() => {
                            label.dataset.text = originalText;
                            labelInner.textContent = originalLabelInnerText;
                        }, 2000);
                    }
                }
            })
            // Log any errors if copying fails
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    // Select the copy button element and add an event listener for clicks
    const copyButton = document.querySelector(selector.button);
    if (copyButton) {
        copyButton.addEventListener('click', copyText);
    }

    // Mobile accordion

    const accordionMobile = document.querySelector(selector.accordionMobile);
    const visibilityBreakpoint = 1140;

    if (!accordionMobile) {
        return;
    }

    const accordionMobileTitle = accordionMobile.querySelector(
        selector.accordionMobileTitle,
    );

    const accordionMobileGroup = accordionMobile.querySelector(
        selector.accordionWrapper,
    );

    // Open accordion on click
    accordionMobileTitle.addEventListener('click', function () {
        if (window.innerWidth <= visibilityBreakpoint) {
            $(accordionMobileGroup).slideToggle(300);
            accordionMobileTitle.classList.toggle('active');

            setTimeout(function () {
                AOS.refresh();
            }, 300);
        }
    });

    // Open accordion if window's width is equal our breakpoint
    window.addEventListener('resize', function () {
        if (window.innerWidth > visibilityBreakpoint) {
            $(accordionMobileGroup).slideDown(300);

            setTimeout(function () {
                AOS.refresh();
            }, 300);
        }
    });
}

export default copyButton;
