import AOS from 'aos';
function accordion(accordion, customSettings) {
    // Merge default settings with custom
    const settings = {
        ...{
            openFirst: 1,
            groupActiveClass: 'accordion-group--active',
            headingActiveClasses: 'accordion-heading--active',
            headingTrackActiveClasses: 'accordion-track--active',
            textActiveClasses: 'accordion-text--active',
            updateUrl: 1,
        },
        ...customSettings
    };

    // CSS classes of Accordion items.
    const selector = {
        block: '.js-accordion',
        group: '.js-accordion_group',
        heading: '.js-accordion_heading',
        headingTrack: '.js-accordion_track',
        link: '.js-accordion_link',
        text: '.js-accordion_text',
    };

    /**
     * Event Handler on group link click
     * @function
     * @param {Event} event
     **/
    const linkClick = (event) => {
        const group = getGroupByChildElement(event.target);
        // Disable scroll page to #hash on click.
        event.preventDefault();

        toggleAccordion(group);
        if (settings.updateUrl) {
            updateURLState(group);
        }
    };

    /**
     * Toggles the data-active attribute, the active classes, and slides the text element up or down.
     * @param {HTMLElement} group - The accordion group to toggle visibility.
     */
    function toggleAccordion (group) {
        // Toggle the data attribute to indicate the visibility state.
        if (groupIsActive(group)) {
            group.dataset.active = '0';
        } else {
            group.dataset.active = '1';
        }

        // Toggles active classes.
        const text = getElementInGroup(group, selector.text);
        const heading = getElementInGroup(group, selector.heading);
        const headingTrack = getElementInGroup(group, selector.headingTrack);

        toggleClasses(group, settings.groupActiveClass);
        toggleClasses(text, settings.textActiveClasses);
        toggleClasses(heading, settings.headingActiveClasses);
        toggleClasses(headingTrack, settings.headingTrackActiveClasses);

        // Slides the text element.
        $(text).slideToggle(300); // jQuery is used here, hence the eslint-disable-next-line comment.

        // refresh Animation on Scroll module Events
        setTimeout(function () {
            AOS.refresh();
        }, 300);
    }

    /**
     * Changes the page URL according to active/inactive accordion group.
     * @param {HTMLElement} group - The accordion group to check visibility.
     */
    function updateURLState (group) {
        let newURL;
        if (groupIsActive(group)) {
            const link = getElementInGroup(group, selector.link);
            newURL = link.href;
        } else {
            // Remove Hash from URL when close accordion group.
            const pageHashTarget = window.location.hash;
            newURL = window.location.href.replace(pageHashTarget, '');
        }
        window.history.pushState({ path: newURL }, '', newURL);
    }

    /**
     * Toggles several HTML classes.
     *
     * @param {HTMLElement} element
     * @param {string} classesString
     * @return boolean
     */
    function toggleClasses(element, classesString) {
        // check parameters to meet
        if(!(element instanceof HTMLElement) || !classesString){
            return false;
        }

        const classes = classesString.split(' ');
        classes.forEach(className => {
            element.classList.toggle(className);
        });
        return true;
    }

    /**
     * @param {HTMLElement} childElement
     * @returns {HTMLElement}
     */
    function getGroupByChildElement(childElement) {
        return childElement.closest(selector.group)
    }

    /**
     *
     * @param {HTMLElement} group
     * @returns {boolean}
     */
    function groupIsActive(group) {
        return Boolean(Number(group.dataset.active));
    }

    /**
     *
     * @param {HTMLElement} group
     * @param {string} element
     * @returns {HTMLElement}
     */
    function getElementInGroup(group, element) {
        return group.querySelector(element);
    }

    /**
     * Scrolls the Page screen to the accordion group.
     * @param {HTMLElement} group
     */
    function scrollPageToGroup(group) {
        window.scrollTo({
            top: group.getBoundingClientRect().top + window.scrollY,
        });
    }

    /**
     * Sets the initial state of the accordion based on the URL hash, and 'openFirst' parameter.
     */
    (function(){
        // The element that matches the URL hash.
        const anchor = location.hash
            ? accordion.querySelector(location.hash)
            : null;
        // Get
        const anchorAccordion = anchor ? anchor.closest(selector.block) : false;

        let accordionGroup;

        if (settings.updateUrl && anchor && anchorAccordion === accordion) {
            // If there is a matching URL hash, find the corresponding group and link and open the group.
            accordionGroup = getGroupByChildElement(anchor);
            scrollPageToGroup(accordionGroup);

        } else if (settings.openFirst) {
            // If accordion has setting 'openFirst', find the first heading and open it.
            accordionGroup = accordion.querySelector(
                `${selector.group}:nth-child(1)`,
            );
        } else {
            accordionGroup = accordion.querySelector(`${selector.group}[data-initially-active='1']`);
        }

        if(accordionGroup){
            setTimeout(() => {
                toggleAccordion(accordionGroup);
            }, 700);
        }
    })();

    /**
     * Adds the Accordion click function as a click event listener to each heading link element.
     */
    accordion.querySelectorAll(selector.link).forEach((link) => {
        link.addEventListener('click', linkClick);
    });
}

export default accordion;
