function accordionMobileInit() {
    // Base hooks for the accordion
    const hooks = {
        group: '[data-accordion-mobile]',
        content: '[data-accordion-mobile-content]',
        title: '[data-accordion-mobile-title]',
        track: '[data-accordion-mobile-track]',
    };

    // All used classed are declared here
    const classes = {
        active: 'is-active',
        trackClicked: 'rotate-180',
    };

    const maxMobileScreenWidth = 1024;

    initAccordions();

    $(hooks.title).click(function (e) {
        if (window.innerWidth < 1024) {
            const group = $(e.target).closest(hooks.group);
            const content = group.find(hooks.content);
            const track = group.find(hooks.track);

            group.toggleClass(classes.active);
            track.toggleClass(classes.trackClicked);

            // eslint-disable-next-line jquery/no-slide
            content.slideToggle(300);
        }
    });

    // Opens the accordion if the browser after resize is wider than the 1024px
    $(window).resize(function (e) {
        const hiddenContent = $(`${hooks.content}[style="display: none;"]`);

        if (window.innerWidth > maxMobileScreenWidth && hiddenContent) {
            hiddenContent.slideDown();
            $(hooks.track).removeClass(classes.trackClicked);
        }
    });

    function initAccordions() {
        if (window.innerWidth < maxMobileScreenWidth) {
            $(hooks.content).slideUp();
            $(hooks.track).toggleClass(classes.trackClicked);
        }
    }
}

export default accordionMobileInit;
