/*
 * External Links
 *
 * Open all external link in new tab
 */
import MicroModal from 'micromodal';

function externalLinks() {
    // Add target="_blank" to external links
    document
        .querySelectorAll('main a:not([target="_blank"])')
        .forEach(function (el) {
            const href = el.getAttribute('href');

            if (!href) {
                return;
            }

            if (checkExternalLink(href)) {
                el.setAttribute('target', '_blank');
                if (!el.getAttribute('aria-label')) {
                    // Get the text content of the link or add your custom description
                    const linkText = `Link opens in a new tab: ${el.textContent.trim()} `;
                    // Set the aria-label attribute
                    el.setAttribute('aria-label', linkText);
                }
            }
        });

    // External/internal link popup
    document.querySelectorAll('main .o-block a').forEach((element) => {
        element.addEventListener('click', function (e) {
            const href = e.target.getAttribute('href');

            const externalModal = document.querySelector(
                '#modal-external-links',
            );

            const internalModal = document.querySelector(
                '#modal-internal-links',
            );

            // Conditions
            const isInFooter = document
                .querySelector('.o-footer-1')
                .contains(e.target);

            const isExternal =
                checkExternalLink(href) && externalModal && !isInFooter;

            const isInternal =
                !checkExternalLink(href) && internalModal && !isInFooter;

            if (isExternal) {
                e.preventDefault();
                MicroModal.show('modal-external-links', {
                    disableScroll: true,
                });
                document
                    .querySelector('#external_link')
                    ?.setAttribute('href', href);
            } else if (isInternal) {
                e.preventDefault();
                MicroModal.show('modal-internal-links', {
                    disableScroll: true,
                });
                document
                    .querySelector('#internal_link')
                    ?.setAttribute('href', href);
            }
        });
    });

    function checkExternalLink(href) {
        if (!href) {
            return false;
        }
        const href_split = href.split('/');

        const protocolPattern = /http(s)?:/;

        return (
            href_split[0]?.match(protocolPattern) &&
            href_split[2] !== window.location.host
        );
    }
}

export default externalLinks;
