import AOS from 'aos';

function menuA1() {
    const selector = {
        heading: '.js-accordion-heading',
        headingTitle: '.js-heading-title',
        group: '.js-accordion-group',
        dropdown: '.js-accordion-dropdown',
        track: '.js-accordion-track',
        currentItem: '.current-menu-item span',
    };

    // Active state classes.
    const state = {
        groupActive: 'is-active',
        trackActive: 'rotate-180',
    };

    // Replace accordion title if a current page exists in the menu
    const currentItem = document.querySelector(selector.currentItem);

    if (currentItem) {
        document.querySelector(selector.headingTitle).innerHTML =
            currentItem.innerHTML;
    }

    const toggleAccordion = (e) => {
        const group = e.target.closest(selector.group);
        const dropdown = group.querySelector(selector.dropdown);
        const track = group.querySelector(selector.track);

        group.classList.toggle(state.groupActive);
        track.classList.toggle(state.trackActive);
        $(dropdown).slideToggle(300); // jQuery is used here, hence the eslint-disable-next-line comment.

        // refresh Animation on Scroll module Events
        setTimeout(function () {
            AOS.refresh();
        }, 300);
    };

    document.querySelectorAll(selector.heading).forEach((heading) => {
        heading.addEventListener('click', toggleAccordion);
    });
}

export default menuA1;
