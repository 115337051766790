/*
 * Slide Out
 *
 * Runs the functionality of functions inside the Slide Out.
 * The actual opening and closing of the slide-out is handled in hamburger.js
 */

const menuItem = document.querySelectorAll('.js-menu-item');
const activeMenuClass = 'm-slide-out__nav-menu--active';

export default function slideOut() {
    menuItem.forEach((item) => {
        item.addEventListener('mouseenter', function () {
            menuItem.forEach((item) => {
                item.classList.remove(activeMenuClass);
            });
            this.classList.add(activeMenuClass);
        });

        item.addEventListener('mouseleave', function () {
            menuItem.forEach((item) => {
                item.classList.remove(activeMenuClass);
            });
        });
    });
}
