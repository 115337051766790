/**
 * Initializes the search bar functionality.
 */
const searchBar = () => {
    /**
     * jQuery object representing the search bar element.
     * @type {JQuery<HTMLElement>}
     */
    const searchBar = $(`.m-search-bar[data-module='searchBar']`);

    /**
     * jQuery object representing the autocomplete element.
     * @type {JQuery<HTMLElement>}
     */
    const algoliaAutocomplete = $('.algolia-autocomplete');

    /**
     * Flag indicating whether the search bar is active.
     * @type {boolean}
     */
    let isSearchBarActive = searchBar.hasClass('m-search-bar--active');

    /**
     * Toggles the search bar visibility and behavior.
     */
    const toggleSearchBar = () => {
        // Check if the search bar is currently visible
        if (!isSearchBarActive) {
            // Logic to show the search bar
            $('.js-header-search-toggle').addClass('a-button-search--active');
            searchBar.slideDown(400, () => {
                searchBar.addClass('m-search-bar--active');
                $('body').addClass('h-scroll-lock');
                if (algoliaAutocomplete.css('display') === 'block') {
                    algoliaAutocomplete.css('display', 'none');
                }
                searchBar.find('.m-search-bar__search-field').focus();
                isSearchBarActive = true; // Update the flag here after showing the search bar
            });
        } else {
            // Logic to hide the search bar
            searchBar.slideUp(400, () => {
                $('.js-header-search-toggle').removeClass(
                    'a-button-search--active',
                );
                searchBar.removeClass('m-search-bar--active');
                $('body').removeClass('h-scroll-lock');
                isSearchBarActive = false; // Update the flag here after hiding the search bar
            });
        }
    };

    /**
     * Handles the keyup event of the search field.
     */
    const handleSearchFieldKeyup = () => {
        const searchField = searchBar.find('.m-search-bar__search-field');
        const popularSearches = searchBar.find('.m-popular-searches');
        const searchContainer = searchField.closest('.m-search-bar');

        if (searchField.val().length) {
            popularSearches.css('display', 'none');
            searchContainer.addClass('m-search-bar--focus');
        } else {
            popularSearches.css('display', 'block');
            searchContainer.removeClass('m-search-bar--focus');
        }
    };

    /**
     * Handles the click event of the search bar close button.
     */
    const handleSearchBarClose = () => {
        const searchField = $(this).siblings('.m-search-bar__search-field');
        searchField.val('').focus();
        searchBar.find('.m-popular-searches').css('display', 'block');
        searchField
            .closest('.m-search-bar--1')
            .removeClass('m-search-bar--focus');
        algoliaAutocomplete.css('display', 'none');
    };

    /**
     * Handles the mouseup event outside the search bar to close it.
     * @param {Event} e - The mouseup event object.
     */
    const handleDocumentMouseUp = (e) => {
        if (
            !searchBar.is(e.target) &&
            searchBar.has(e.target).length === 0 &&
            searchBar.hasClass('m-search-bar--active')
        ) {
            searchBar.find('.m-search-bar').slideUp(400, () => {
                // Animation complete callback
                searchBar.removeClass('m-search-bar--active');
                $('body').removeClass('h-scroll-lock');
                isSearchBarActive = false; // Update search bar active state
            });
        }
    };

    $('.js-header-search-toggle').on('click', toggleSearchBar);
    $('.m-search-bar__search-field').keyup(handleSearchFieldKeyup);
    $('.m-search-bar__close-btn').on('click', handleSearchBarClose);
    $(document).on('mouseup', handleDocumentMouseUp);
};

export default searchBar;
