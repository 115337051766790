/**
 * Toggle mobile navigation dropdowns.
 *
 * @description This function sets up click event handlers for menu items with children
 * and the mobile navigation dropdown to enable toggling behavior.
 */
function toggleMobileNavigationDropdowns() {
    const selector = '.js-nav-drop';
    const activeClass = 'is-active';
    const linkItemHasChildren = 'a.menu-item-has-children';

    /**
     * Toggle mobile navigation dropdown on click.
     *
     * @description This function toggles the visibility and appearance of the mobile navigation dropdown
     * along with associated styles for the active class and the plus toggler.
     *
     * @param {HTMLElement} dropdown - The dropdown element.
     * @returns {void}
     */
    function toggleDropdown(dropdown) {
        dropdown.classList.toggle(activeClass);
        const subMenu = dropdown.nextElementSibling;
        if (subMenu && subMenu.classList.contains('m-nav__sub-menu')) {
            // TODO: replace jquery method
            $(subMenu).slideToggle();
        }
        const plusToggler = dropdown.querySelector('.js-plus-toggler');
        if (plusToggler) {
            plusToggler.classList.toggle('a-plus-toggler--opened');
        }
    }

    /**
     * Handle click event on menu items with children.
     *
     * @param {Event} event - The click event.
     * @returns {void}
     */
    function handleMenuItemClick(event) {
        event.preventDefault();
        const nextSibling = event.target.nextElementSibling;
        if (nextSibling && nextSibling.matches(selector)) {
            toggleDropdown(nextSibling);
        }
    }

    // Attach click event to menu items with children
    const linkItems = document.querySelectorAll(linkItemHasChildren);
    linkItems.forEach((item) =>
        item.addEventListener('click', handleMenuItemClick),
    );

    // Attach click event to the mobile navigation dropdown
    const dropdowns = document.querySelectorAll(selector);
    dropdowns.forEach((dropdown) => {
        toggleDropdown(dropdown);
        dropdown.addEventListener('click', () => toggleDropdown(dropdown));
    });
}

export default toggleMobileNavigationDropdowns;
