import Helpers from '/src/global/js/helpers/helpers.js';

/**
 * Custom event for when dropdown is opened
 * @event dropdownOpened
 * @type {Event}
 */

const dropdownOpenedEvent = new Event('dropdownOpened', {
    bubbles: true,
    composed: true,
});
/**
 * Custom event for when dropdown is opened by click
 * @event dropdownOpenedByClick
 * @type {Event}
 */
const dropdownOpenedByClickEvent = new Event('dropdownOpenedByClick', {
    bubbles: true,
    composed: true,
});
/**
 * Custom event for when dropdown is opened by hover
 * @event dropdownOpenedByHover
 * @type {Event}
 */
const dropdownOpenedByHoverEvent = new Event('dropdownOpenedByHover', {
    bubbles: true,
    composed: true,
});
/**
 * Custom event for when dropdown is hidden
 * @event dropdownHidden
 * @type {Event}
 */
const dropdownHiddenEvent = new Event('dropdownHidden', {
    bubbles: true,
    composed: true,
});
/**
 * Custom event for when dropdown is hidden by click
 * @event dropdownHiddenByClick
 * @type {Event}
 */
const dropdownHiddenByClickEvent = new Event('dropdownHiddenByClick', {
    bubbles: true,
    composed: true,
});
/**
 * Custom event for when dropdown is hidden by hover
 * @event dropdownHiddenByHover
 * @type {Event}
 */
const dropdownHiddenByHoverEvent = new Event('dropdownHiddenByHover', {
    bubbles: true,
    composed: true,
});

/**
 * Initialize dropdown functionality
 * @function
 */
function dropdownInit() {
    const dropdowns = document.querySelectorAll('.js-dropdown');

    // Stop if dropdowns didn't found
    if (dropdowns.length === 0) {
        return null;
    }

    dropdowns.forEach(function (dropdown) {
        // Get dropdown params
        let params = Helpers.getJsonFromAttr(dropdown.dataset.dropdown);

        // Set toggler
        let toggler = dropdown.querySelector('.js-dropdown-toggler');

        // Set bar
        let bar = dropdown.querySelector('.js-dropdown-bar');

        // Stop if Toggler or Bar didn't found
        if (toggler == null || bar == null) {
            return null;
        }
        // Get action
        let action = params.action ? params.action : 'hover';

        // Set active class
        let activeClass = 'js-dropdown-active';

        // Set 'position' modificator
        let position = params.position ? params.position : 'bottom-left';
        let positionClass = 'js-dropdown-' + position;
        dropdown.classList.add(positionClass);

        // When action is 'click'
        if (action == 'click') {
            // Toggler bar by click in the toggler
            toggler.addEventListener('click', (e) => {
                e.preventDefault();

                if (dropdown.classList.contains(activeClass)) {
                    dropdownHide(dropdown, activeClass, toggler, 'click');
                } else {
                    dropdownShow(dropdown, activeClass, toggler, 'click');
                }
            });

            // Hide bar when click outside of dropdown
            document.addEventListener('click', function (event) {
                if (
                    dropdown.classList.contains(activeClass) &&
                    event.target != toggler &&
                    !toggler.contains(event.target) &&
                    event.target != bar &&
                    !bar.contains(event.target)
                ) {
                    dropdownHide(dropdown, activeClass, toggler, 'click');
                }
            });
        }

        // When action is 'hover'
        else if (action == 'hover') {
            var dropdownHideTimeout;

            var togglerHovered = false;
            var barHovered = false;

            // Disable toggler link on touch devices
            toggler.addEventListener('click', (e) => {
                if (
                    'ontouchstart' in window ||
                    navigator.maxTouchPoints > 0 ||
                    navigator.msMaxTouchPoints > 0
                ) {
                    e.preventDefault();
                }
            });

            // Show dropdown when toggler is hovered
            toggler.addEventListener('mouseover', (e) => {
                var togglerHovered = true;
                Helpers.removeClassVanillaJs(dropdowns, activeClass, dropdown, [
                    dropdownHiddenEvent,
                    dropdownHiddenByHoverEvent,
                ]);
                clearTimeout(dropdownHideTimeout);
                dropdownShow(dropdown, activeClass, toggler);
            });

            // Hide dropdown when toggler and bar are not hovered
            toggler.addEventListener('mouseout', (e) => {
                var togglerHovered = false;
                if (!barHovered) {
                    dropdownHideTimeout = setTimeout(
                        dropdownHide.bind(null, dropdown, activeClass, toggler),
                        600,
                    );
                }
            });

            // Show dropdown when toggler is focused. Accessibility support.
            toggler.addEventListener('focusin', (e) => {
                var togglerHovered = true;
                clearTimeout(dropdownHideTimeout);
                dropdownShow(dropdown, activeClass, toggler);
            });

            // Hide dropdown when toggler is not focused and bar is not hovered. Accessibility support.
            toggler.addEventListener('focusout', (e) => {
                var togglerHovered = false;
                if (!barHovered) {
                    dropdownHide(dropdown, activeClass, toggler);
                }
            });

            // Don't hide dropdown when bar is hovered
            bar.addEventListener('mouseover', (e) => {
                var barHovered = true;
                clearTimeout(dropdownHideTimeout);
                dropdownShow(dropdown, activeClass, toggler);
            });

            // Hide dropdown when toggler and bar are not hovered
            bar.addEventListener('mouseout', (e) => {
                var barHovered = false;
                if (!togglerHovered) {
                    dropdownHideTimeout = setTimeout(
                        dropdownHide.bind(null, dropdown, activeClass, toggler),
                        600,
                    );
                }
            });

            // Don't hide dropdown when bar is focused. Accessibility support.
            bar.addEventListener('focusin', (e) => {
                var barHovered = true;
                clearTimeout(dropdownHideTimeout);
                dropdownShow(dropdown, activeClass, toggler);
            });

            // Hide dropdown when toggler and bar are not focused. Accessibility support.
            bar.addEventListener('focusout', (e) => {
                var barHovered = false;
                if (!togglerHovered) {
                    dropdownHide(dropdown, activeClass, toggler);
                }
            });
        }
    });
}

/**
 * Show dropdown bar
 * @function
 * @param {HTMLElement} dropdown - The dropdown element
 * @param {HTMLElement} toggler - The toggler element
 * @param {string} activeClass - The active class for thed ropdown element
 * @param {string} [by="hover"] - The way the dropdown is opened (by click or hover)
 */
function dropdownShow(dropdown, activeClass, toggler, action = 'hover') {
    dropdown.classList.add(activeClass);
    dropdown.dispatchEvent(dropdownOpenedEvent);

    if (action == 'hover') {
        dropdown.dispatchEvent(dropdownOpenedByHoverEvent);
    } else if (action == 'click') {
        dropdown.dispatchEvent(dropdownOpenedByClickEvent);
    }

    // Update aria-expanded attribute based on activeClass
    toggler.setAttribute('aria-expanded', activeClass ? 'true' : 'false');
}

/**
 * Hide dropdown bar
 * @param {HTMLElement} dropdown - The dropdown element
 * @param {HTMLElement} toggler - The toggler element
 * @param {string} activeClass - The active class for the dropdown element
 * @param {string} action - The way the dropdown is hidden (by click or hover)
 */
function dropdownHide(dropdown, activeClass, toggler, action = 'hover') {
    dropdown.classList.remove(activeClass);
    dropdown.dispatchEvent(dropdownHiddenEvent);

    if (action == 'hover') {
        dropdown.dispatchEvent(dropdownHiddenByHoverEvent);
    } else if (action == 'click') {
        dropdown.dispatchEvent(dropdownHiddenByClickEvent);
    }

    // Update aria-expanded attribute when activeClass is removed
    toggler.setAttribute('aria-expanded', 'false');
}

export default dropdownInit;
