/**
 * Initializes the Search Bar B Molecule functionality.
 */
const searchBarB = () => {
    /**
     * Main block
     * @type {NodeListOf<HTMLElement>}
     */
    const moduleBlocks = document.querySelectorAll(
        `[data-module='searchBarB']`,
    );

    moduleBlocks.forEach((moduleBlock) => {
        const inputField = moduleBlock.querySelector(`input[name='s']`);
        const popularSearchesBlock = moduleBlock.querySelector(
            '.m-popular-searches',
        );

        if (inputField && popularSearchesBlock) {
            inputField.addEventListener('focusin', (event) => {
                if (event.target.value === '') {
                    showPopularSearchBlock(popularSearchesBlock);
                } else {
                    hidePopularSearchBlock(popularSearchesBlock);
                }
            });
            inputField.addEventListener('focusout', (event) => {
                setTimeout(() => {
                    hidePopularSearchBlock(popularSearchesBlock);
                }, 100);
            });
            inputField.addEventListener('keyup', (event) => {
                if (event.target.value === '') {
                    showPopularSearchBlock(popularSearchesBlock);
                } else {
                    hidePopularSearchBlock(popularSearchesBlock);
                }
            });
        }
    });

    /**
     * Show the popular search block
     * @param {HTMLElement} popularSearchBlock
     */
    const showPopularSearchBlock = (popularSearchBlock) => {
        popularSearchBlock.classList.remove('hidden');
    };

    /**
     * Hide the popular search block
     * @param {HTMLElement} popularSearchBlock
     */
    const hidePopularSearchBlock = (popularSearchBlock) => {
        popularSearchBlock.classList.add('hidden');
    };
};

export default searchBarB;
