import 'video.js/dist/video-js.css';
import 'videojs-youtube/dist/Youtube.min';

import videojs from 'video.js';

function videoInit() {
    const videos = document.querySelectorAll('video-js');

    videos?.forEach((videoPlayer) => {
        videojs(videoPlayer);
    });
}

export default videoInit;
